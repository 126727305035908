import { MetaDefinition } from "@angular/platform-browser";

export function getMetaTagTitleAndDescription(page: string): { metaTag: MetaDefinition, title: string } {
    switch (page) {
        case MetaTagPages.LOGIN:
            return {
                title: 'Login - My Account | FBITN',
                metaTag: {
                    name: 'description',
                    content: 'Login to My Account to locate your proof-of-insurance card, view and print policy documents, pay bills, file a claim, make changes to your policies, and more.'
                }
            }
        case MetaTagPages.CREATE_ACCOUNT:
            return {
                title: 'Create Account - My Account | FBITN',
                metaTag: {
                    name: 'description',
                    content: "Set up My Account to view and manage your insurance policies all in one place. Registering for your online customer account is easy. You'll just need to have a policy number, zip code, and personal information handy."
                }
            }
        case MetaTagPages.FORGOT_USER_NAME:
            return {
                title: 'Forgot Username - My Account | FBITN',
                metaTag: {
                    name: 'description',
                    content: 'Forgot your username? Reset your My Account username here. Retrieve your login credentials in a few simple steps.'
                }
            }
        case MetaTagPages.FORGOT_PASSWORD:
            return {
                title: 'Forgot Password  - My Account | FBITN',
                metaTag: {
                    name: 'description',
                    content: 'Forgot your password? Reset your My Account password here. Retrieve your login credentials in a few simple steps.'
                }
            }
        case MetaTagPages.PAY_MY_BILL:
            return {
                title: 'Pay My Bill - My Account | FBITN',
                metaTag: {
                    name: 'description',
                    content: 'My Account allows you to quickly pay your insurance bill online. For questions about your policy, please contact the Farm Bureau Insurance Customer Service Center at 877-876-2222. You may also contact your local Farm Bureau office.'
                }
            }
        default:
            return {
                title: 'My Account | FBITN',
                metaTag: {
                    name: 'description',
                    content: ''
                }
            }
    }
}

export enum MetaTagPages{
    LOGIN="LOGIN",
    PAY_MY_BILL="PAY_MY_BILL",
    FORGOT_USER_NAME="FORGOT_USER_NAME",
    FORGOT_PASSWORD= "FORGOT_PASSWORD",
    CREATE_ACCOUNT = "CREATE_ACCOUNT",
    DEFAULT="DEFAULT"
}

