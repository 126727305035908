export class RegisterSecurityForm {
    public question1: string;
    public answer1: string;
    public question2: string;
    public answer2: string;
    public question3: string;
    public answer3: string;
    public confirmationCheck: boolean;
    public registerThiscomputer: boolean;

    constructor(init?: Partial<RegisterSecurityForm>) {
        Object.assign(this, init);
    }
}
